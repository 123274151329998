import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { AuthService } from '../state/auth/auth.service';
import { ConfigurationService } from '../services/configuration.service';
import { AuthQuery } from '../state/auth/auth.query';
import { catchError, filter, take } from 'rxjs/operators';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { AuthToken } from '../state/auth/auth.model';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private authService: AuthService,
        private injector: Injector,
        private configurationService: ConfigurationService,
        private authQuery: AuthQuery
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const mercuryApi = this.configurationService.value.api.mercury;
        const basePath = [ mercuryApi.baseUrl, mercuryApi.broker ];
        const isApiUrl = request.url.startsWith(basePath.join('/'));
        let isUserRetrievalRoute = false;

        try {
            isUserRetrievalRoute = (new URL(request.url)).pathname.endsWith('auth/token/me');
        } catch (err) {
            isUserRetrievalRoute = false;
        }

        const addAuthentication = request.headers.has('X-Add-Authentication');

        const isLoggedIn = this.authQuery.isLoggedIn();
        const authToken = this.authQuery.getAuthToken();

        if (!this.authService) {
            this.authService = this.injector.get(AuthService);
        }

        if (isApiUrl || addAuthentication) {
            if (authToken && authToken.token) {
                request = this.addToken(request, authToken.token);
            }

            return next.handle(request).pipe(catchError(error => {
                if (error instanceof HttpErrorResponse && (
                    (error.status === 401 || error.status === 403) ||
                    (isUserRetrievalRoute && error.status === 400)
                )) {
                    return this.handle401Error(request, next) as Observable<HttpEvent<any>>;
                } else {
                    return throwError(error);
                }
            }));
        }

        return next.handle(request);
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);


            return this.authService.refreshToken().pipe(
                switchMap((token: AuthToken) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token.token);
                    return next.handle(this.addToken(request, token.token));
                }),
                catchError(_ => {
                    this.isRefreshing = false;
                    return this.authService.logout();
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }),
                catchError(_ => {
                    return this.authService.logout();
                }));
        }
    }
}
