import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate, mapToResolve } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { NoAuthGuard } from './shared/guards/no-auth.guard';
import { AuthResolver } from './shared/state/auth/auth.resolver';
import { OverlayComponent } from './shared/components/overlay/overlay.component';
import { UnsubscribeComponent } from './routes/unsubscribe/unsubscribe.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'app/feeds',
        pathMatch: 'full'
    },
    {
        path: 'auth',
        canActivate: mapToCanActivate([NoAuthGuard]),
        loadChildren: () => import('./routes/auth/auth.module').then(mod => mod.AuthModule)
    },
    {
        path: 'app',
        canActivate: mapToCanActivate([AuthGuard]),
        resolve: {
            user: mapToResolve(AuthResolver)
        },
        children: [
            {
                path: 'feeds',
                loadChildren: () => import('./routes/feeds/feeds.module').then(mod => mod.FeedsModule)
            },
            {
                path: 'review',
                loadChildren: () => import('./routes/review/review.module').then(mod => mod.ReviewModule)
            },
            {
                path: '',
                component: OverlayComponent,
                outlet: 'overlay',
                children: [
                    {
                        path: 'dialog',
                        loadChildren: () => import('./routes/overlays/overlays.module').then(mod => mod.OverlaysModule)
                    }
                ]
            }
        ]
    },
    {
        path: 'unsubscribe',
        canActivate: mapToCanActivate([NoAuthGuard]),
        component: UnsubscribeComponent
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
