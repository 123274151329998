import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../state/auth/auth.service';
import { AuthQuery } from '../state/auth/auth.query';
import { AuthGuard } from './auth.guard';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard {
    constructor(
        private authService: AuthService,
        private authQuery: AuthQuery,
        private router: Router
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot): boolean {
        if (
            this.authQuery.isRefreshTokenStillValid() &&
            next.routeConfig.canActivate.includes(AuthGuard)
        ) {
            this.router.navigate(['/app/feeds'], { queryParams: next.queryParams });
            return false;
        }

        return true;
    }
}
