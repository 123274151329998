import { ActionReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        return localStorageSync({
            keys: [{ review: ['list'] }],
            rehydrate: true
        })(reducer)(state, action);
    };
}
