import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { delay, map } from "rxjs/operators";
import * as AlertActions from "../actions/alert.actions";

@Injectable()
export class AlertEffects {

    public hideAlert$ = createEffect(() => this.actions$.pipe(
        ofType(AlertActions.activateAlert),
        delay(10000),
        map(() => AlertActions.deactivateAlert())
    ));

    constructor(private actions$: Actions) { }
}
