import { Component, HostListener } from '@angular/core';
import { ThemeService } from './shared/services/theme.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { ApplicationViewService } from './shared/state/application-view/application-view.service';
import { AlertsService } from './shared/state/alerts/alerts.service';
import { environment } from '../environments/environment';
import { TranslocoService } from '@ngneat/transloco';


@Component({
    selector: 'app-root',
    template: `
        <app-dev-toolbar *ngIf="!production"></app-dev-toolbar>
        <router-outlet></router-outlet>
        <router-outlet name="overlay"></router-outlet>
    `,
    styles: []
})
export class AppComponent {
    didRequestPermission = false;
    production = environment.production;

    constructor(
        private themeService: ThemeService,
        private configurationService: ConfigurationService,
        private applicationViewsService: ApplicationViewService,
        private notificationsService: AlertsService
    ) {
        this.applicationViewsService.fetch();
        this.themeService.applyTheme(this.configurationService.value.theme);

        // translateService.setDefaultLang('en');
        // translateService.use(localStorage.getItem('language'));
        // translateService.addLangs(this.configurationService.value.availableLanguages);
    }

    @HostListener('click')
    onMouseEnter() {
        if (this.didRequestPermission) {
            return;
        }

        this.notificationsService.requestNativeNotificationPermissions();
        this.didRequestPermission = true;
    }

}
