import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ConfigurationWindow } from './declarations';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import { booleanToString, getParamValueQueryString, stringToBoolean } from './app/shared/helpers/general.helper';
import { createConfiguration } from './app/shared/models/configuration';


let env = 'default';
let production = false;

if (environment.production) {
    enableProdMode();
    enableAkitaProdMode();

    env = window.location.host;
} else {
    const fakeEnvironment = getParamValueQueryString('fakeEnvironment');
    const productionEnvironment = getParamValueQueryString('productionEnvironment') || sessionStorage.getItem('productionEnvironment');
    production = productionEnvironment ? stringToBoolean(productionEnvironment) : false;

    if (fakeEnvironment) {
        env = fakeEnvironment;
    } else {
        const sessionEnv = sessionStorage.getItem('fakeEnvironment');
        env = sessionEnv ? sessionEnv : 'default';
    }

    sessionStorage.setItem('fakeEnvironment', env);
    sessionStorage.setItem('productionEnvironment', booleanToString(production));
}

persistState({
    include: [
        'auth',
        'formsManager.globalSearch',
        'contents.contentEditing',
        'latest-global-searches',
        'alerts.ui'
    ],

    preStoreUpdate(storeName: string, state: any) {
        if (storeName === 'contents') {
            if (!state.hasOwnProperty('currentNewContent') || !state.hasOwnProperty(('editContents'))) {
                return { currentNewContent: null, editContents: {} };
            }
        }

        return state;
    }
});


const endpoint = `assets/configs/${env}.json`;


fetch(endpoint)
.then(response => response.json())
.then(json => {
    const defaultEnv = createConfiguration({});

    const config = (window as unknown as ConfigurationWindow).configuration = (production || environment.production) ? json : {
        ...json,
        api: {
            ...defaultEnv.api
        },
        environmentId: defaultEnv.environmentId
    };

    if (!localStorage.getItem('language')) {
        localStorage.setItem('language', (window as unknown as ConfigurationWindow).configuration.defaultLanguage);
    }

    // change the window title already here, if the environment config wants to change it
    if (config.windowTitle) {
        document.title = config.windowTitle;
    }

    return platformBrowserDynamic().bootstrapModule(AppModule);
})
.catch(err => console.error(err));


