import { createReducer, on } from "@ngrx/store";
import * as AlertActions from "../actions/alert.actions";
import { AlertState } from "../state/alert.state";

export const initialState: AlertState = undefined;

export const alertReducer = createReducer(
    initialState,
    on(AlertActions.activateAlert, (state, { alert }) =>
        ({ ...state, 
            type: alert.type,
            message: alert.message
        })
    ),
    on(AlertActions.deactivateAlert, () =>
        undefined
    )
);
