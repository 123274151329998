import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Alert } from '../../store/state/alert.state';
import * as UnsubscribeActions from './store/actions/unsubscribe.actions';
import * as AlertSelectors from '../../store/selectors/alert.selectors';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  public environmentUserKey: string;
  public email: string;
  public alert$: Observable<Alert>;

  constructor(
    private route: ActivatedRoute,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.environmentUserKey = params.environmentUserKey;
      this.email = params.email;
    });

    this.alert$ = this.store.select(AlertSelectors.selectAlert);
  }

  public unsubscribe(): void {
    this.store.dispatch(UnsubscribeActions.unsubscribeUser({
      environmentUserKey: this.environmentUserKey
    }));
  }
}
