import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MercuryBrokerApi } from "../../../shared/api/mercury-broker.api";
import { MercuryApi } from "../../../shared/api/mercury.api";
import { ConfigurationService } from "../../../shared/services/configuration.service";

@Injectable({providedIn: 'root'})
export class UnsubscribeApi extends MercuryApi {

    constructor(
        http: HttpClient,
        configurationService: ConfigurationService
    ) {
        super(http, configurationService)
    }

    unsubscribe(environmentUserKey: string): Observable<Object> {
        return this.post('unsubscribe', { key: environmentUserKey });
    }
}
