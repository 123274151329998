import { HttpClient } from '@angular/common/http';
import { provideTransloco, Translation, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import { Injectable, isDevMode, NgModule } from '@angular/core';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { langToLocaleMapping } from './shared/models/lang';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {
    }

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        provideTransloco({
            config: {
                availableLangs: [
                    { id: 'de', label: 'Deutsch' },
                    { id: 'en', label: 'English' }
                ],
                defaultLang: localStorage.getItem('language') || 'de',
                reRenderOnLangChange: true,
                prodMode: !isDevMode()
            },
            loader: TranslocoHttpLoader
        }),
        provideTranslocoLocale({
            langToLocaleMapping
        })
    ]
})
export class TranslocoRootModule {
}
