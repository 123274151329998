import { BrowserModule } from '@angular/platform-browser';
import { ENVIRONMENT_INITIALIZER, LOCALE_ID, NgModule, NgZone, inject } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AngularSplitModule } from 'angular-split';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { SharedModule } from './shared/shared.module';
import { AuthResolver } from './shared/state/auth/auth.resolver';
import { ToastrModule } from 'ngx-toastr';
import { ClrPopoverToggleService } from '@clr/angular';
import { TranslocoRootModule } from './transloco-root.module';
import { StoreModule } from '@ngrx/store';
import { reviewReducer } from './routes/review/store/reducers/review.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers } from './store/reducers/meta.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UnsubscribeComponent } from './routes/unsubscribe/unsubscribe.component';
import { UnsubscribeEffects } from './routes/unsubscribe/store/effects/unsubscribe.effects';
import { alertReducer } from './store/reducers/alert.reducer';
import { AlertEffects } from './store/effects/alert.effects';
import { DevtoolsOptions, akitaDevtools } from '@datorama/akita';

export function createLocalId() {
    return localStorage.getItem('language');
}

export function provideAkitaDevtools(options: Partial<DevtoolsOptions> = {}) {
    return {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useFactory() {
        return () => {
          akitaDevtools(inject(NgZone), options);
        };
      }
    };
}

@NgModule({
    declarations: [
        AppComponent,
        UnsubscribeComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        AkitaNgRouterStoreModule,
        AngularSplitModule,
        ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }),
        TranslocoRootModule,
        StoreModule.forRoot({
            alert: alertReducer,
            review: reviewReducer
        }, { metaReducers }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true
        }),
        EffectsModule.forRoot([
            AlertEffects,
            UnsubscribeEffects
        ])
    ],
    providers: [
        ClrPopoverToggleService,
        AuthResolver,
        // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: LOCALE_ID, useFactory: createLocalId },
        provideAkitaDevtools()
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
