<clr-main-container>
    <div class="content-container">
        <div class="content-area">
            <div class="clr-row" style="justify-content: center;">
                <div class="clr-col-lg-4 clr-col-md-6 clr-col-12">
                    <div class="card" style="text-align: center">
                        <div class="card-block">
                            <div class="card-text">
                                {{ 'routes.unsubscribe.message' | transloco: {'email': email} }}
                            </div>
                        </div>
                        <div class="card-footer">
                            <clr-button-group>
                                <clr-button class="btn-primary" (click)="unsubscribe()">{{'routes.unsubscribe.button' | transloco}}</clr-button>
                            </clr-button-group>
                        </div>
                    </div>
                    <clr-alert *ngIf="(alert$ | async) as alert" [clrAlertType]="alert.type" [clrAlertClosable]="false">
                        <clr-alert-item>
                            <span class="alert-text">{{alert.message | transloco}}</span>
                        </clr-alert-item>
                    </clr-alert>
                </div>
            </div>
        </div>
    </div>
</clr-main-container>