import { HttpClient } from "@angular/common/http";
import { ConfigurationService } from "../services/configuration.service";
import { ApiResponse, BaseApi } from "./base.api";

export abstract class MercuryApi extends BaseApi<ApiResponse> {
    protected constructor(
        http: HttpClient,
        configurationService: ConfigurationService
    ) {
        const basePath = [
            configurationService.value.api.mercury.baseUrl,
            'broker'
        ];

        super(basePath.join('/'), http, false);
    }
}
