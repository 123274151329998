import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, delay, map, mergeMap } from "rxjs/operators";
import { UnsubscribeApi } from "../../api/unsubscribe.api";
import * as UnsubscibeActions from "../actions/unsubscribe.actions";
import * as AlertActions from "../../../../store/actions/alert.actions"

@Injectable()
export class UnsubscribeEffects {

    public unsubscribeUser$ = createEffect(() => this.actions$.pipe(
        ofType(UnsubscibeActions.unsubscribeUser),
        mergeMap((action) => this.unsubscribeApi.unsubscribe(action.environmentUserKey).pipe(
            map(() => UnsubscibeActions.unsubscribeUserSuccess()),
            catchError(() => of(UnsubscibeActions.unsubscribeUserFail()))
        ))
    ));

    public showSuccessAlert$ = createEffect(() => this.actions$.pipe(
        ofType(UnsubscibeActions.unsubscribeUserSuccess),
        map(() => AlertActions.activateAlert({ 
            alert: { type: 'success', message: 'routes.unsubscribe.user.success' }
        }))
    ));

    public showFailAlert$ = createEffect(() => this.actions$.pipe(
        ofType(UnsubscibeActions.unsubscribeUserFail),
        map(() => AlertActions.activateAlert({ 
            alert: { type: 'danger', message: 'routes.unsubscribe.user.fail' }
        }))
    ));

    constructor(
        private actions$: Actions,
        private unsubscribeApi: UnsubscribeApi
    ) { }
}
