import { createReducer, on } from "@ngrx/store";
import * as ReviewActions from "../actions/review.actions";
import * as AlertActions from "../../../../store/actions/alert.actions";
import { ReviewState } from "../state/review.state";

export const initialState: ReviewState = {
    list: []
};

export const reviewReducer = createReducer(
    initialState,
    on(ReviewActions.addContentToList, (state, { content }) =>
        ({ ...state, list: state.list.concat(content) })),
    on(ReviewActions.removeContentFromList, (state, { contents }) =>
        ({ ...state, list: state.list.filter(item =>
            !contents.map(item => item.id).includes(item.id))
        })
    ),
    on(ReviewActions.resetList, (state) =>
        ({...state, list: initialState.list})),
);
