import { createAction, props } from "@ngrx/store";

export const unsubscribeUser = createAction(
    '[Unsubscribe] Unsubscribe User',
    props<{ 
        environmentUserKey: string;
    }>()
);

export const unsubscribeUserSuccess = createAction(
    '[Unsubcribe] Unsubscribe User Success'
);

export const unsubscribeUserFail = createAction(
    '[Unsubcribe] Unsubscribe User Fail'
);
